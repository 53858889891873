<template>
  <div id="app-layout">           
   <div class="home__title">
              <h2 class="home__title__bg">PLAN DE SITE</h2>
            </div>         
            <div class="pagecontent">
              <ul class="sitemap">
                  <li><router-link to="/">Accueil</router-link></li>
                  <li><router-link to="Actualites">Actualités</router-link></li>
                   <ul class="sitemap__sous">
                        <li><router-link to="Lettredemonnotaire">La Lettre de mon Notaire</router-link></li>
                        <li><router-link to="Conseils">Conseils des Notaires</router-link></li>
                  </ul>
                  <li><router-link to="Prestations">Nos Prestations</router-link></li>
                  <ul class="sitemap__sous">
                        <li><router-link to="Missions">Missions</router-link></li>
                        <li><router-link to="Services">Services en ligne</router-link></li>
                        <li><router-link to="Guides">Guides pratiques</router-link></li>
                        <li><router-link to="Liensutiles">Liens Utiles</router-link></li>
                  </ul>
                  <li><router-link to="Office">Notre Office</router-link></li>
                  <!--<ul class="sitemap__sous">
                        <li><anchor-router-link :to="{name: 'Office', hash: '#presentation'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: -100,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">
                                        Présentation</anchor-router-link></li>
                        <li><anchor-router-link :to="{name: 'Office', hash: '#equipe'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: -100,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">L'équipe</anchor-router-link></li>
                      </ul>-->
                  <li><router-link to="Contact">Contact</router-link></li>
                  <li><router-link to="Sitemap">Plan de site</router-link></li>
                  <li><router-link to="MentionsLegales">Mentions Légales</router-link></li>
                  <li><router-link to="Privacy">CGU et Politique de Confidentialité</router-link></li>
              </ul>
            </div>
  </div>
</template>

<script>
//import AnchorRouterLink from 'vue-anchor-router-link'

  export default {
    metaInfo: {
    // Children can override the title.
    title: 'Plan du Site - Office Notarial de Me Montagner',
    link: [
    {rel: 'canonical', href: 'https://angelique-montagner.notaires.fr/Sitemap'}
   ]
  },
    name: "sitemap"/*,
  components: {
    AnchorRouterLink
    },*/
  }
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;

.sitemap {
    padding: 50px;
    width: 80%;
    margin: auto;
    @media screen and (max-width: 979px){
        width: 100%;
    }
    &__sous {
        margin-left: 50px;
    }
}

.pagecontent {
    width: 75%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(250,250,250,0.95);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}
</style>